import { useCallback, memo, FC } from "react";
import { ValidationErrors } from "@shipworks/hub-utility/src/validator/validatorTypes";
import { MaterialComponents } from "./MaterialComponents";
import { PhoneNumberInput } from "./index";
import {
  GridFormInputField,
  GridFormField,
  GridFormCountrySelect,
  GridFormStateProvSelect,
} from "./grid-form-fields";
import { IAddress } from "../../shared/types/IAddress";

/**
 * allContact includes ["firstName", "lastName", "company", "email", "phone", "website"]
 * allAddress includes ["street1", "street2", "city", "stateProvCode", "postalCode", "countryCode"]
 */
type AddressFieldValues =
  | "allContact"
  | "allAddress"
  | "firstName"
  | "lastName"
  | "company"
  | "email"
  | "phone"
  | "website"
  | "street1"
  | "street2"
  | "city"
  | "stateProvCode"
  | "postalCode"
  | "countryCode";

export class AddressFields {
  firstName = false;
  lastName = false;
  company = false;
  email = false;
  phone = false;
  website = false;
  street1 = false;
  street2 = false;
  city = false;
  stateProvCode = false;
  postalCode = false;
  countryCode = false;

  constructor(fields: AddressFieldValues[]) {
    fields.forEach((value) => {
      if (value === "allContact") {
        this.firstName = true;
        this.lastName = true;
        this.company = true;
        this.email = true;
        this.phone = true;
        this.website = true;
      } else if (value === "allAddress") {
        this.street1 = true;
        this.street2 = true;
        this.city = true;
        this.stateProvCode = true;
        this.postalCode = true;
        this.countryCode = true;
      } else {
        this[value] = true;
      }
    });
  }
}

export interface AddressFormV2Props {
  id: string;
  address: IAddress;
  onChange: MaterialComponents.OnPropChangedCallback;
  validationErrors?: ValidationErrors;
  fieldsToInclude?: AddressFields;
  fieldsToDisable?: AddressFields;
  disabled?: boolean;
  allowPhoneExtension?: boolean;
  useFormattedPhoneNumber?: boolean;
}

const breakpoints = {
  company: { xs: 12, md: 6 },
  firstName: { xs: 12, md: 6 },
  lastName: { xs: 12, md: 6 },
  email: { xs: 12, md: 6 },
  phone: { xs: 12, md: 6 },
  website: { xs: 12, md: 6 },
  street1: { xs: 12, md: 6 },
  street2: { xs: 12, md: 6 },
  city: { xs: 12, md: 3 },
  stateProvCode: { xs: 12, md: 3 },
  postalCode: { xs: 12, md: 3 },
  country: { xs: 12, md: 3 },
};

const AddressFormV2: FC<AddressFormV2Props> = ({
  id,
  address,
  onChange,
  validationErrors = {},
  fieldsToInclude,
  fieldsToDisable,
  disabled = false,
  allowPhoneExtension = false,
  useFormattedPhoneNumber = false,
}) => {
  const updateState = useCallback((value) => onChange("stateProvCode", value), [onChange]);
  const updateCountry = useCallback((value) => onChange("countryCode", value), [onChange]);

  return (
    <>
      {fieldsToInclude?.firstName && (
        <GridFormInputField
          breakpoints={breakpoints.firstName}
          id={`${id}-firstName`}
          title="First Name"
          onChange={onChange}
          value={address.firstName}
          name="firstName"
          validationErrors={validationErrors["firstName"]}
          InputProps={{
            disabled: disabled || fieldsToDisable?.firstName,
          }}
        />
      )}
      {fieldsToInclude?.lastName && (
        <GridFormInputField
          breakpoints={breakpoints.lastName}
          id={`${id}-lastName`}
          title="Last Name"
          onChange={onChange}
          value={address.lastName}
          name="lastName"
          validationErrors={validationErrors["lastName"]}
          InputProps={{
            disabled: disabled || fieldsToDisable?.lastName,
          }}
        />
      )}
      {fieldsToInclude?.company && (
        <GridFormInputField
          breakpoints={breakpoints.company}
          id={`${id}-company`}
          title="Company"
          onChange={onChange}
          value={address.company}
          name="company"
          validationErrors={validationErrors["company"]}
          InputProps={{
            disabled: disabled || fieldsToDisable?.company,
          }}
        />
      )}
      {fieldsToInclude?.email && (
        <GridFormInputField
          breakpoints={breakpoints.email}
          id={`${id}-email`}
          title="Email"
          onChange={onChange}
          value={address.email}
          name="email"
          validationErrors={validationErrors["email"]}
          InputProps={{
            disabled: disabled || fieldsToDisable?.email,
          }}
        />
      )}
      {fieldsToInclude?.phone && (
        <GridFormField breakpoints={breakpoints.phone} title="Phone">
          <PhoneNumberInput
            id={`${id}-phone`}
            title="Phone"
            onChange={onChange}
            value={address.phone}
            name="phone"
            error={validationErrors["phone"]}
            InputProps={{
              disabled: disabled || fieldsToDisable?.phone,
            }}
            allowExtension={allowPhoneExtension}
            useFormattedValue={useFormattedPhoneNumber}
          />
        </GridFormField>
      )}
      {fieldsToInclude?.website && (
        <GridFormInputField
          breakpoints={breakpoints.website}
          id={`${id}-website`}
          title="Website"
          onChange={onChange}
          value={address.website}
          name="website"
          validationErrors={validationErrors["website"]}
          InputProps={{
            disabled: disabled || fieldsToDisable?.website,
          }}
        />
      )}
      {fieldsToInclude?.street1 && (
        <GridFormInputField
          breakpoints={breakpoints.street1}
          id={`${id}-street1`}
          title="Address 1"
          onChange={onChange}
          value={address.street1}
          name="street1"
          validationErrors={validationErrors["street1"]}
          InputProps={{
            disabled: disabled || fieldsToDisable?.street1,
          }}
        />
      )}
      {fieldsToInclude?.street2 && (
        <GridFormInputField
          breakpoints={breakpoints.street2}
          id={`${id}-street2`}
          title="Address 2"
          onChange={onChange}
          value={address.street2}
          name="street2"
          validationErrors={validationErrors["street2"]}
          InputProps={{
            disabled: disabled || fieldsToDisable?.street2,
          }}
        />
      )}
      {fieldsToInclude?.city && (
        <GridFormInputField
          breakpoints={breakpoints.city}
          id={`${id}-city`}
          title="City"
          onChange={onChange}
          value={address.city}
          name="city"
          validationErrors={validationErrors["city"]}
          InputProps={{
            disabled: disabled || fieldsToDisable?.city,
          }}
        />
      )}
      {fieldsToInclude?.stateProvCode &&
      (!address.countryCode || address.countryCode === "US" || address.countryCode === "USA") ? (
        <GridFormStateProvSelect
          id={`${id}-stateProvCode`}
          breakpoints={breakpoints.stateProvCode}
          title="State"
          displayAbbreviation={true}
          onChange={updateState}
          value={address.stateProvCode}
          validationErrors={validationErrors["stateProvCode"]}
          disabled={disabled || fieldsToDisable?.stateProvCode}
        />
      ) : (
        <GridFormInputField
          breakpoints={breakpoints.stateProvCode}
          id={`${id}-stateProvCode`}
          title="State"
          onChange={onChange}
          value={address.stateProvCode}
          name="stateProvCode"
          validationErrors={validationErrors["stateProvCode"]}
          InputProps={{
            disabled: disabled || fieldsToDisable?.stateProvCode,
          }}
        />
      )}
      {fieldsToInclude?.postalCode && (
        <GridFormInputField
          breakpoints={breakpoints.postalCode}
          id={`${id}-postalCode`}
          title="Zip"
          onChange={onChange}
          value={address.postalCode}
          name="postalCode"
          validationErrors={validationErrors["postalCode"]}
          InputProps={{
            disabled: disabled || fieldsToDisable?.postalCode,
          }}
        />
      )}
      {fieldsToInclude?.countryCode && (
        <GridFormCountrySelect
          breakpoints={breakpoints.country}
          title="Country"
          id={`${id}-country`}
          onChange={updateCountry}
          value={address.countryCode}
          validationErrors={validationErrors["countryCode"]}
          disabled={disabled || fieldsToDisable?.countryCode}
        />
      )}
    </>
  );
};

export default memo(AddressFormV2);
