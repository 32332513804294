import { PopOverInProgressSpinner } from ".";
import styled from "@emotion/styled";

const LoadingContainer = styled.div`
  background-image: url("/images/login-background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 0;
`;

const LoginLoader = ({ text }) => (
  <LoadingContainer>
    <PopOverInProgressSpinner inProgress={true} text={text} />
  </LoadingContainer>
);

export default LoginLoader;
