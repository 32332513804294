import { Box, Typography } from "@mui/material";

interface SectionLoadErrorProps {
  context?: string;
  messages?: Array<string>;
}

const SectionLoadError = ({ context, messages }: SectionLoadErrorProps) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" p={3}>
      {messages ? (
        messages.length > 1 ? (
          <Typography>
            The following errors occurred:
            <ul>
              {messages.map((x) => (
                <li>{x}</li>
              ))}
            </ul>
          </Typography>
        ) : (
          <Typography>{messages[0]}</Typography>
        )
      ) : (
        <Typography>
          Failed to load{context ? ` ${context}` : ""}. Please try refreshing the page.
        </Typography>
      )}
    </Box>
  );
};

export default SectionLoadError;
