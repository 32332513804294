import { Typography } from "@mui/material";

import withStyles from "@mui/styles/withStyles";

/** Create a style object
 * @param {import("@mui/material").Theme} theme - Theme used by the app
 * @returns {object} Style object
 */
const styles = (theme) => ({
  label: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(2),
  },
});

/**
 * Form field
 * @param {Products.ProductFormFieldProps} props - The properties passed into this component
 * @returns {JSX.Element}
 */
const FormField = (props) => (
  <label className={props.classes.label}>
    <Typography variant="body2">
      {props.title}
      {props.required ? " *" : ""}
    </Typography>
    {props.children}
  </label>
);

export default withStyles(styles)(FormField);
