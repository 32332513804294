import { memo, ElementType, FC } from "react";
import { Button as MuiButton, ButtonProps as MuiButtonProps } from "@mui/material";

// component exists on the MUI Button api, but for some reason it is not included in the MUI ButtonProps
interface ButtonProps extends MuiButtonProps {
  component?: ElementType;
}

const SecondaryButton: FC<ButtonProps> = ({ children, ...rest }) => (
  <MuiButton variant="outlined" disableElevation {...rest}>
    {children}
  </MuiButton>
);

export default memo(SecondaryButton);
