import { TableCell, TableRow } from "@mui/material";
import { FC } from "react";
import SectionLoadError from "./SectionLoadError";

interface TableLoadErrorRowProps {
  /** The context in which the error occurred*/
  tableContext: string;
  /** The number of columns the error row should occupy */
  colSpan: number;
}

/**
 * Creates a table row to display an error
 */
const TableLoadErrorRow: FC<TableLoadErrorRowProps> = ({ tableContext, colSpan }) => (
  <TableRow>
    <TableCell colSpan={colSpan} align="center">
      <SectionLoadError context={tableContext} />
    </TableCell>
  </TableRow>
);

export default TableLoadErrorRow;
