import { memo } from "react";
import { GridFormComboBoxField } from "./index";

export interface GridFormMonthSelectProps {
  id: string;
  title: string;
  breakpoints: any;
  className?: any;
  onChange(selectedValue): void;
  value: any;
  validationErrors: any;
  disabled?: boolean;
  name?: string;
  displayNumber?: boolean;
}

const months = [
  { name: "January", value: "1" },
  { name: "February", value: "2" },
  { name: "March", value: "3" },
  { name: "April", value: "4" },
  { name: "May", value: "5" },
  { name: "June", value: "6" },
  { name: "July", value: "7" },
  { name: "August", value: "8" },
  { name: "September", value: "9" },
  { name: "October", value: "10" },
  { name: "November", value: "11" },
  { name: "December", value: "12" },
];

/**
 * Select component for months
 */
const GridFormMonthSelect = ({
  id,
  title,
  breakpoints,
  className,
  onChange,
  value,
  validationErrors,
  disabled = false,
  name = "Month",
  displayNumber = false,
}: GridFormMonthSelectProps) => (
  <GridFormComboBoxField
    id={id}
    title={title}
    breakpoints={breakpoints}
    className={className}
    onChange={onChange}
    value={value}
    options={months}
    validationErrors={validationErrors}
    labelField={displayNumber ? "value" : "name"}
    valueField="value"
    name={name}
    disabled={disabled}
  />
);

export default memo(GridFormMonthSelect);
