import { useMemo, useCallback, memo, FC } from "react";
import {
  Autocomplete,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  Box,
  FormHelperTextProps,
  Checkbox,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import createValidationError from "../../shared/utility/createValidationError";

export interface IComboBoxOption {
  label: string;
  value: string;
  icon?: any;
}

export interface ComboBoxV2Props {
  /** The HTML element ID  */
  id: string;
  /** Change handler */
  onChange(selectedValue): void;
  /** The currently selected value(s) */
  value: any;
  /** The options to display */
  options: Array<IComboBoxOption | Record<string, any>>;
  /** The field of the option object to use as the label. Defaults to "label" */
  labelField?: string;
  /** The field of the option object to use as the value. Defaults to "value" */
  valueField?: string;
  /** The field of the option object to use as the icon. Defaults to "" */
  iconField?: string;
  /** The validation errors associated with this field  */
  validationErrors?: any;
  /** The name of this field. Used to display validation errors  */
  name: string;
  /** Whether or not this field is disabled  */
  disabled?: boolean;
  /** Whether or not to allow multiple values are allowed */
  multiple?: boolean;
  /** Whether or not selecting an empty value is allowed */
  allowEmpty?: boolean;
  /** Override for the default option component */
  renderOption?(option, state): JSX.Element;
}

const ComboBoxV2: FC<ComboBoxV2Props> = ({
  id,
  onChange,
  value,
  options,
  labelField = "label",
  valueField = "value",
  iconField = "",
  validationErrors,
  name,
  disabled = false,
  multiple = false,
  allowEmpty = true,
  renderOption = undefined,
}) => {
  const getOptionLabel = (option) => option[labelField] ?? "";
  const emptyValue = useMemo(
    () => [{ [labelField]: "", [valueField]: "" }],
    [labelField, valueField],
  );

  const optionsWithEmptyValue = useMemo(() => [emptyValue, ...options], [emptyValue, options]);

  const selectedValue = multiple
    ? options.filter((option) => value.includes(option[valueField]))
    : options.find((option) => option[valueField] === value) || emptyValue;

  const changeHandler = useCallback(
    (event, newValue) => {
      if (multiple) {
        const values = newValue.map((x) => x[valueField]);
        onChange(values);
      } else {
        onChange(newValue[valueField]);
      }
    },
    [multiple, onChange, valueField],
  );

  return (
    <Autocomplete
      id={id}
      options={multiple || !allowEmpty ? options : optionsWithEmptyValue}
      disableClearable
      value={selectedValue}
      disableCloseOnSelect={multiple}
      autoSelect={!multiple}
      popupIcon={<ExpandMore />}
      onChange={changeHandler}
      isOptionEqualToValue={(option, value) => option[valueField] === value[valueField]}
      autoHighlight
      getOptionLabel={getOptionLabel}
      renderInput={(params) => {
        const shouldShowIcon =
          Boolean(iconField) &&
          Boolean(selectedValue) &&
          Boolean(selectedValue[iconField]) &&
          params.inputProps["value"] === selectedValue[labelField];

        // @ts-ignore - Interface doesn't like this, but if we take it out, it causes a bunch of browser errors
        const formHelperTextProps: FormHelperTextProps = { component: "div" };

        return (
          <TextField
            {...params}
            size="small"
            error={!!validationErrors}
            helperText={createValidationError(validationErrors, name)}
            inputProps={{
              ...params.inputProps,
              // disable autocomplete and autofill, this seems like a magic string, but it's not
              autoComplete: "new-password",
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: shouldShowIcon ? (
                <Box pl={1}>
                  <InputAdornment position="start">{selectedValue[iconField]()}</InputAdornment>
                  {params.InputProps.startAdornment}
                </Box>
              ) : (
                params.InputProps.startAdornment
              ),
              style: { backgroundColor: "#FFFFFF" },
            }}
            FormHelperTextProps={formHelperTextProps}
          />
        );
      }}
      renderOption={
        renderOption ||
        ((props, option, { selected }) => {
          return (
            <li {...props}>
              {multiple && <Checkbox checked={selected} />}
              {iconField && option[iconField] ? (
                <Grid container spacing={1} direction="row">
                  <Grid
                    container
                    item
                    component="span"
                    style={{ width: "auto" }}
                    alignItems="center"
                  >
                    {option[iconField]()}
                  </Grid>
                  <Grid item>
                    <Typography>{getOptionLabel(option)}</Typography>
                  </Grid>
                </Grid>
              ) : (
                getOptionLabel(option)
              )}
            </li>
          );
        })
      }
      openText=""
      closeText=""
      disabled={disabled}
      multiple={multiple}
    />
  );
};

export default memo(ComboBoxV2);
