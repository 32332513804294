import TextField from "@mui/material/TextField";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

const KeyboardDatePicker = ({
  id,
  value,
  onChange,
  maxDate,
  minDate,
  rest = {}
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        inputFormat="MM/dd/yyyy"
        value={value}
        onChange={onChange}
        minDate={minDate}
        maxDate={maxDate}
        renderInput={(rest) => <TextField id={id} size="small" variant="outlined" {...rest} />}
      />
    </LocalizationProvider>
  );
};

export default KeyboardDatePicker;
