import { FC } from "react";
import { Button, ButtonProps } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  linkButton: {
    textDecoration: "underline",
    padding: 0,
    height: "1rem",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

interface LinkButtonProps extends ButtonProps {
  target?: string;
  variant?: "contained" | "outlined" | "text";
}

const LinkButton: FC<LinkButtonProps> = (props) => {
  const classes = useStyles();

  return <Button color="primary" className={classes.linkButton} disableRipple {...props} />;
};

export default LinkButton;
