const createValidationError = (error, title) => {
  let errorMessage;

  if (Array.isArray(error)) {
    errorMessage = (
      <>
        {error.map((x) => (
          <p style={{ margin: 0 }} key={x}>{`${title} ${x}`}</p>
        ))}
      </>
    );
  } else {
    errorMessage = <p style={{ margin: 0 }}>{`${title} ${error}`}</p>;
  }

  return error ? errorMessage : undefined;
};

export default createValidationError;
