const sortByText = (accessor) => (a, b) => {
  const stringA = accessor(a).toUpperCase(); // ignore upper and lowercase
  const stringB = accessor(b).toUpperCase(); // ignore upper and lowercase

  if (stringA < stringB) {
    return -1;
  }

  if (stringA > stringB) {
    return 1;
  }

  // strings must be equal
  return 0;
};

export default sortByText;
