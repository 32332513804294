import { lazy, Suspense } from "react";
import { LoginLoader } from "shared-components/material-components";
import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";

const Swat = lazy(() => import(/* webpackChunkName: "swat" */ "./swat/Swat"));
const Hub = lazy(() => import(/* webpackChunkName: "hub" */ "./hub/app/Hub"));
const Callbacks = lazy(() => import(/* webpackChunkName: "callbacks" */ "./callbacks/Callbacks"));

const getRenderComponent = () => {
  if (window.location.pathname.startsWith("/admin")) {
    return <Swat />;
  } else if (window.location.pathname.startsWith("/callback")) {
    return <Callbacks />;
  }

  return <Hub />;
};

const App = () => {
  const theme = createTheme();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<LoginLoader text="Loading..." />}>{getRenderComponent()}</Suspense>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
