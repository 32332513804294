import { memo } from "react";
import { Button as MuiButton, ButtonProps } from "@mui/material";

const PrimaryButton = ({ children, ...rest }: ButtonProps) => (
  <MuiButton variant="contained" color="primary" disableElevation {...rest}>
    {children}
  </MuiButton>
);

export default memo(PrimaryButton);
