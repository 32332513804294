import { memo } from "react";
import { GridFormComboBoxField } from "./index";
import states from "../../../shared/states";

interface GridFormStateProvSelectProps {
  id: string;
  title: string;
  breakpoints: any;
  className?: any;
  onChange(selectedValue): void;
  value: any;
  validationErrors: any;
  disabled?: boolean;
  displayAbbreviation?: boolean;
}

const GridFormStateProvSelect = ({
  id,
  title,
  breakpoints,
  className,
  onChange,
  value,
  validationErrors,
  disabled = false,
  displayAbbreviation = false,
}: GridFormStateProvSelectProps) => (
  <GridFormComboBoxField
    id={id}
    title={title}
    breakpoints={breakpoints}
    className={className}
    onChange={onChange}
    value={value}
    options={states}
    validationErrors={validationErrors}
    labelField={displayAbbreviation ? "abbreviation" : "name"}
    valueField="abbreviation"
    name="State"
    disabled={disabled}
  />
);

export default memo(GridFormStateProvSelect);
