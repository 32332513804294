import { Grid, CircularProgress, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Popover from "../app-components/Popover";

const useStyles = makeStyles(() => ({
  popOver: {
    position: "absolute",
    inset: 0,
    zIndex: 10000,
    background: "rgba(0,0,0,0.6)",
  },
  popOverParent: {
    height: "100%",
    width: "100%",
    position: "relative",
  },
  spinner: {
    height: "100%",
    top: "50%",
    margin: "0px",
    width: "100%",
  },
  text: {
    color: "#e3e3e3",
  },
}));

const InProgressSpinner = ({ text }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      spacing={1}
      className={classes.spinner}
    >
      <Grid item>
        <CircularProgress size={15} className={classes.text} />
      </Grid>
      <Grid item>
        <Typography className={classes.text}>{text}</Typography>
      </Grid>
    </Grid>
  );
};

/**
 * Pop Over in progress spinner that covers its children with an opaque control and doesn't affect layout
 * @param {MaterialComponents.InProgressSpinnerProps} props
 * @returns {JSX.Element}
 */
const PopOverInProgressSpinner = ({ children, inProgress, text }) => (
  <Popover open={inProgress} Content={() => <InProgressSpinner text={text} />}>
    {children}
  </Popover>
);

export default PopOverInProgressSpinner;
