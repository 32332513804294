import { memo } from "react";
import { Grid, Typography, TypographyVariant } from "@mui/material";

import { StyleRules } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";

export interface GridFormFieldProps {
  breakpoints: any;
  className?: any;
  classes?: any;
  title: string;
  subTitle?: string;
  children?: any;
  labelTypographyVariant?: TypographyVariant;
}
const styles = (theme): StyleRules => ({
  label: {
    display: "flex",
    flexDirection: "column",
  },
  hint: {
    color: theme.palette.text.hint,
  },
});

const GridFormField = ({
  breakpoints,
  className,
  classes,
  title,
  subTitle,
  children,
  labelTypographyVariant = "body2",
}: GridFormFieldProps) => (
  <Grid item className={className} {...breakpoints}>
    <label className={classes.label}>
      <Typography variant={labelTypographyVariant}>
        {title}
        {subTitle && (
          <Typography variant="body2" component="span" className={classes.hint}>
            {" "}
            {subTitle}
          </Typography>
        )}
      </Typography>
      {children}
    </label>
  </Grid>
);

export default withStyles(styles)(memo(GridFormField));
