import NumberFormat from "react-number-format";
import { TextField } from "@mui/material";
import createValidationError from "../../shared/utility/createValidationError";

const PhoneNumberInput = ({
  id,
  title,
  value,
  onChange,
  name,
  error,
  allowExtension = false,
  useFormattedValue = false,
  InputProps = {},
}) => {
  const format = (value) => {
    const getChar = (string, position) => string[position] ?? "_";

    const getRange = (string, start, end) => {
      let range = "";
      for (let i = start; i < end; i++) {
        range += getChar(string, i);
      }

      return range;
    };

    const getBase = (string) =>
      `(${getRange(string, 0, 3)}) ${getRange(string, 3, 6)}-${getRange(string, 6, 10)}`;

    return value.length <= 10 ? getBase(value) : `${getBase(value)} ext ${value.substring(10)}`;
  };

  return (
    <NumberFormat
      id={id}
      value={value}
      onValueChange={(values) =>
        onChange(name, useFormattedValue ? values.formattedValue : values.value)
      }
      customInput={TextField}
      //@ts-ignore
      size="small"
      format={allowExtension ? format : "(###) ###-####"}
      mask="_"
      allowEmptyFormatting
      error={!!error}
      helperText={createValidationError(error, title)}
      FormHelperTextProps={{
        component: "div",
      }}
      {...InputProps}
    />
  );
};

export default PhoneNumberInput;
