/**
 * Overrides the default console.debug behavior so that logging can be turned off
 * and on by flipping the DEBUG switch. This is so that we can have verbose logging
 * in production if we need it and it can be off the rest of the time so that it doesn't
 * get in the way.
 */
var DEBUG = false;

const setup = () => {
  console.debug = (message) => {
    if (DEBUG) {
      console.log(message);
    }
  };
};

export default setup;
