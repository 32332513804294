import { TableRow, TableCell, Box, Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((/** @type {import("@mui/material").Theme} */ theme) => ({
  emptyCell: {
    color: theme.palette.text.hint,
  },
}));

/**
 * An empty table row to display when there is no data
 */
const EmptyTableRow = ({ context, colSpan }) => {
  const classes = useStyles();
  return (
    <TableRow>
      <TableCell className={classes.emptyCell} colSpan={colSpan}>
        <Box display="flex" justifyContent="center" my={2}>
          <Typography>{`No ${context} to display`}</Typography>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default EmptyTableRow;
