import { useCallback, memo } from "react";
import { ValidationErrors } from "@shipworks/hub-utility/src/validator/validatorTypes";
import { GridFormInputField, GridFormComboBoxField, GridFormMonthSelect } from "./grid-form-fields";
import { IComboBoxOption } from "./ComboBoxV2";

export type CreditCardFieldsToInclude =
  | "all"
  | "nameOnCard"
  | "cardType"
  | "cardNumber"
  | "cvv"
  | "expirationMonth"
  | "expirationYear";

export interface CreditCard {
  nameOnCard?: string;
  cardType?: string;
  cardNumber: string;
  cvv?: string;
  expirationMonth: string;
  expirationYear: string;
}

interface CreditCardFormV2Props {
  id: string;
  creditCard: CreditCard;
  onChange: any;
  validationErrors?: ValidationErrors;
  disableCardNumber?: boolean;
  fieldsToInclude: CreditCardFieldsToInclude[];
}

const defaultCardTypes: IComboBoxOption[] = [
  { label: "Visa", value: "Visa" },
  { label: "Mastercard", value: "MasterCard" },
  { label: "American Express", value: "AmericanExpress" },
  { label: "Discover", value: "Discover" },
];

const createYearArray = (): { value: string }[] => {
  const currentYear: number = new Date().getFullYear();

  const years: { value: string }[] = [];
  for (let i = 0; i < 20; i++) {
    years.push({ value: (currentYear + i).toString() });
  }

  return years;
};

const years = createYearArray();

const breakpoints = {
  nameOnCard: { xs: 12 },
  cardType: { xs: 12, md: 4 },
  cardNumber: { xs: 12, md: 8 },
  cvv: { xs: 12, md: 4 },
  expirationMonth: { xs: 12, md: 4 },
  expirationYear: { xs: 12, md: 4 },
};

const CreditCardFormV2 = ({
  id,
  creditCard,
  onChange,
  validationErrors = {},
  disableCardNumber = false,
  fieldsToInclude,
}: CreditCardFormV2Props) => {
  const updateCardType = useCallback((value) => onChange("cardType", value), [onChange]);

  const updateExpirationMonth = useCallback(
    (value) => onChange("expirationMonth", value),
    [onChange],
  );

  const updateExpirationYear = useCallback(
    (value) => onChange("expirationYear", value),
    [onChange],
  );

  return (
    <>
      {fieldsToInclude?.some((value) => value === "all" || value === "nameOnCard") && (
        <GridFormInputField
          breakpoints={breakpoints.nameOnCard}
          id={`${id}-nameOnCard`}
          title="Name on Card"
          name="nameOnCard"
          value={creditCard?.nameOnCard || ""}
          onChange={onChange}
          validationErrors={validationErrors.nameOnCard}
        />
      )}
      {fieldsToInclude?.some((value) => value === "all" || value === "cardType") && (
        <GridFormComboBoxField
          breakpoints={breakpoints.cardType}
          title="Card Type"
          id={`${id}-cardType`}
          value={creditCard?.cardType}
          onChange={updateCardType}
          validationErrors={validationErrors["cardType"]}
          name="Card Type"
          options={defaultCardTypes}
        />
      )}
      {fieldsToInclude?.some((value) => value === "all" || value === "cardNumber") && (
        <GridFormInputField
          breakpoints={breakpoints.cardNumber}
          id={`${id}-cardNumber`}
          title="Card Number"
          name="cardNumber"
          value={creditCard.cardNumber}
          onChange={onChange}
          validationErrors={validationErrors.cardNumber}
          disabled={disableCardNumber}
        />
      )}
      {fieldsToInclude?.some((value) => value === "all" || value === "cvv") && (
        <GridFormInputField
          breakpoints={breakpoints.cvv}
          id={`${id}-cvv`}
          title="CVV"
          name="cvv"
          value={creditCard.cvv || ""}
          onChange={onChange}
          validationErrors={validationErrors.cvv}
        />
      )}
      {fieldsToInclude?.some((value) => value === "all" || value === "expirationMonth") && (
        <GridFormMonthSelect
          breakpoints={breakpoints.expirationMonth}
          title="Exp. Month"
          id={`${id}-expirationMonth`}
          displayNumber={true}
          value={creditCard?.expirationMonth}
          onChange={updateExpirationMonth}
          validationErrors={validationErrors["expirationMonth"]}
          name="Exp. Month"
        />
      )}
      {fieldsToInclude?.some((value) => value === "all" || value === "expirationYear") && (
        <GridFormComboBoxField
          breakpoints={breakpoints.expirationYear}
          title="Exp. Year"
          id={`${id}-expirationYear`}
          value={creditCard?.expirationYear}
          onChange={updateExpirationYear}
          options={years}
          labelField="value"
          valueField="value"
          name="Exp. Year"
          validationErrors={validationErrors["expirationYear"]}
        />
      )}
    </>
  );
};

export default memo(CreditCardFormV2);
