import ComboBoxV2, { ComboBoxV2Props } from "./ComboBoxV2";
import sortByText from "../../shared/utility/sortByText";
import { listAll } from "../../shared/order-sources/orderSources";

const sortedStoreTypes = listAll().sort(sortByText((x) => x.displayName));

const StoreTypeSelect = (props: Omit<ComboBoxV2Props, "options" | "labelField" | "valueField">) => (
  <ComboBoxV2 {...props} options={sortedStoreTypes} labelField="displayName" valueField="id" />
);

export default StoreTypeSelect;
