import { Box, Typography } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import LinkButton from "./LinkButton";

export interface IBreadCrumbStep {
  title: string;
  id: string;
  onClick: (step: IBreadCrumbStep) => void;
}
export interface IBreadCrumbProps {
  steps: IBreadCrumbStep[];
  currentStepId: string;
}

const BreadCrumb = ({ steps, currentStepId }: IBreadCrumbProps): JSX.Element | null =>
  currentStepId !== steps[0].id ? (
    <Box display="flex" flexWrap="wrap" alignItems="baseline">
      {steps.slice(0, steps.findIndex((step) => currentStepId === step.id) + 1).map((step, index) =>
        steps[index].id !== currentStepId ? (
          <Box key={index} display="flex" alignItems="flex-start">
            <LinkButton onClick={() => step.onClick(step)}>
              <Typography variant="h3">{step.title}</Typography>
            </LinkButton>
            <ChevronRight />
          </Box>
        ) : (
          <Typography key={index} variant="h3">
            {step.title}
          </Typography>
        ),
      )}
    </Box>
  ) : null;

export default BreadCrumb;
