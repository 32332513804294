import { TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import FormField from "./FormField";
import createValidationError from "../../shared/utility/createValidationError";

const useStyles = makeStyles((/** @type {import("@mui/material").Theme} */ theme) => ({
  textField: {
    "& input": {
      backgroundColor: theme.palette.common.white,
    },
  },
}));

/**
 * Input field
 * @param {object} props - The properties passed into this component
 * @returns {JSX.Element}
 */
const InputField = (props) => {
  const classes = useStyles();
  return (
    <FormField {...props}>
      <TextField
        size="small"
        {...props}
        error={!!props.error}
        helperText={createValidationError(props.error, props.title)}
        value={props.value || ""}
        onChange={(e) => props.onChange(props.name, e.target.value)}
        className={classes.textField}
        FormHelperTextProps={{
          component: "div",
        }}
      />
    </FormField>
  );
};

export default InputField;
