import * as React from "react";
import { Fade } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";

interface IProps {
  open?: boolean;
  onClose?: () => any;
  Content: React.FC;
  withCloseButton?: boolean;
}

const useStyles = makeStyles(() => ({
  popOver: {
    position: "absolute",
    inset: 0,
    zIndex: 1000,
    background: "rgba(0,0,0,0.6)",
    padding: "1rem",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "7px",
      height: "7px",
      backgroundColor: "gray",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "darkGray",
      borderRadius: "10px",
    },
    //Firefox Scrollbar
    scrollbarWidth: "thin",
  },
  popOverParent: {
    height: "100%",
    width: "100%",
    position: "relative",
  },
  exitButtonContainer: {
    width: "100%",
    textAlign: "end",
    color: "White",
    "&:hover": {
      color: "lightgray",
    },
  },
}));

const Popover: React.FC<IProps> = ({ open, children, onClose, withCloseButton, Content }) => {
  const classes = useStyles();

  return (
    <div className={classes.popOverParent}>
      {open && (
        <Fade in timeout={500}>
          <div className={classes.popOver}>
            {withCloseButton && (
              <div className={classes.exitButtonContainer}>
                <CloseIcon onClick={() => onClose && onClose()} />
              </div>
            )}
            <Content />
          </div>
        </Fade>
      )}
      {children}
    </div>
  );
};

export default Popover;
