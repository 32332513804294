import { memo } from "react";
import GridFormField, { GridFormFieldProps } from "./GridFormField";
import ComboBoxV2, { ComboBoxV2Props } from "../ComboBoxV2";

export interface GridFormComboBoxFieldProps extends GridFormFieldProps, ComboBoxV2Props {}

const getInputProps = (props: GridFormComboBoxFieldProps) => {
  const inputProps: any = { ...props };

  delete inputProps.title;
  delete inputProps.breakpoints;
  delete inputProps.className;

  return inputProps;
};

const GridFormComboBoxField = (props: GridFormComboBoxFieldProps) => {
  const { title, breakpoints, className } = props;

  const inputProps = getInputProps(props);

  return (
    <GridFormField breakpoints={breakpoints} className={className} title={title}>
      <ComboBoxV2 {...inputProps} />
    </GridFormField>
  );
};

export default memo(GridFormComboBoxField);
